import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Seo from '../../Components/SeoComponent/Seo';
import { DivTag, HeadTag, Button, InputField, ImgTag, FormTag } from '../../Components/Tags/Tags';
import SliderCrousel from '../../Components/SliderLoginPage/SliderCrousel';
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import ReCAPTCHA from 'react-google-recaptcha'; 
import { login,verifyPin } from "../../Services/authService";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalWithPinInput from "../../Components/ModalWithPinComponent/ModalWithPinInput";
import {
  showSuccessToast,
  showErrorToast,
} from "../../utils/toastUtils";
import Confetti from 'react-confetti-boom';

const LoginUserPage = () => {
  const [user_id, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [tempToken, setTempToken] = useState('');
  const [captchaToken, setCaptchaToken] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = !!Cookies.get('authToken');
    if (isLoggedIn) {
      navigate('/Dashboard');
    }
  }, [navigate]);


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

const handleCaptchaChange = () => {
  setCaptchaToken(!captchaToken);
};

const handleLogin = async (e) => {
  e.preventDefault();

  if (user_id.trim() === '' || password.trim() === '') {
    showErrorToast('Please enter Username and Password');
    return;
  }

  if (!captchaToken) {
    showErrorToast("Please complete the reCAPTCHA.");
    return;
  }

  setIsLoading(true);

  try {
    const result = await login({ user_id, password, captchaToken });
    // console.log(result);

    // Check if login failed due to missing location
    if (result.message === "Please enable location services.") {
      showErrorToast("Location permission denied. Please allow access to location.");
      return;
    }

    if (result.success === false && result.data.temp_token) {
      setTempToken(result.data.temp_token); // Store tempToken after login
      showSuccessToast(result.message || "Step 1 successful. Please verify your PIN.");
      setIsModalOpen(true); // Show PIN verification modal
    } else {
      showErrorToast(result.data.message || "Login failed.");
    }
  } catch (error) {
    console.error(error);
    if (error.message === "Location access denied or unavailable.") {
      showErrorToast("Location permission denied. Please allow access to your location.");
    } else {
      showErrorToast("Server error occurred.");
    }
  } finally {
    setIsLoading(false);
  }
};




  const handlePinSubmit = async () => {
    if (pin.length < 6) {
      showErrorToast("Please enter a valid PIN.");
      return;
    }
  
    setIsLoading(true); // Show loading state
  
    try {
      const result = await verifyPin(pin.join(''), tempToken); // Join PIN digits
      if (result.success) {
        navigate('/Dashboard'); // Redirect to dashboard
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      showErrorToast("Error verifying PIN.");
    } finally {
      setIsLoading(false); // Hide loading state
    }
  };

  return (
    <>
      <Seo />
      {/* <ToastContainer /> */}
      <ModalWithPinInput
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          tempToken={tempToken} // Pass tempToken to the modal
          onPinSubmit={handlePinSubmit} // Handle PIN submission
        />

      <DivTag className="bg-slate-700 md:w-full min-h-screen flex flex-col sm:flex-row">
        <SliderCrousel />
        
        <DivTag className="flex flex-col items-center w-full sm:w-[35%]  p-1 bg-gradient-to-r to-blue-800/70 from-white/50 bg-white/30 backdrop-blur-lg rounded-lg shadow-lg">
          <ImgTag className='md:mt-32 w-[65%] md:w-[70%]' src="assets/images/idspay_logo_log2.png" alt="logo" />
          <DivTag className="text-center mt-10 mb-8 md:mb-0 md:mt-8 ">
            <h1
  className="text-xl md:text-2xl font-bold text-transparent stroke-[1px] stroke-white tracking-widest text-center relative"
  style={{
    textShadow: '12px 12px 32px #cecece',
    WebkitTextStroke: '0.1rem #fff',
    animation: 'flicker 7s infinite'
  }}
  
>Welcome Back</h1>

<style jsx>{`
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    41.99%, 42%, 48%, 49% { opacity: 0; }
    43.01%, 49.01% { opacity: 1; }
  }
`}</style>


            <h1
  className="text-2xl md:text-4xl font-bold text-transparent stroke-[1px] stroke-white tracking-widest text-center relative"
  style={{
    textShadow: '12px 12px 32px #cecece',
    WebkitTextStroke: '0.1rem #fff',
    animation: 'flicker 3s infinite'
  }}
  
>Login To User Panel</h1>

<style jsx>{`
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    41.99%, 42%, 48%, 49% { opacity: 0; }
    43.01%, 49.01% { opacity: 1; }
  }
`}</style>

          </DivTag>
         
        </DivTag>

        <FormTag className="w-full sm:w-[39%] bg-gradient-to-r from-blue-800/50 to-white/60 backdrop-blur-xl border border-white/20 rounded-xl shadow-xl md:p-24 hover:bg-white/10">
         
         
          <DivTag className="flex flex-col w-full sm:w-full sm:mt-[7rem] mb-16 p-6 overflow-auto">
         
            <label className="text-white font-semibold text-xl">User Name</label>
            <div className="flex flex-row gap-1 mt-1 bg-white/80 items-center rounded-md py-2 mb-3 px-1 w-full border-blue-500 shadow-md shadow-blue-700 transition duration-300 ease-in-out transform hover:scale-105">
              <FaRegUser size={26} color='blue' />
              <InputField type="text" className="bg-inherit focus:outline-none pl-1 w-full "
                id="user_id"
                name="user_id"
                value={user_id}
                onChange={(e) => setUsername(e.target.value)}
                placeholder='Enter User Name'
                required
                autofocus={true}
                
              />
            </div>
            <label className="text-white font-semibold text-xl">Password</label>
            <div className="flex flex-row gap-1 bg-white/80 rounded-md py-2 px-1 w-full border-blue-500 mt-1 shadow-md shadow-blue-700 transition duration-300 ease-in-out transform hover:scale-105">
              <RiLockPasswordFill size={26} color='blue' />
              <InputField className="bg-inherit focus:outline-none pl-1 w-full"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                
                placeholder="Enter Password"
              />
            </div>
            

            <div className="mt-5 transform scale-75 origin-center transition duration-300 ease-in-out hover:scale-90">
  <ReCAPTCHA
    sitekey="6LdVjzIqAAAAAGc1B3BkXGFyUZmHTUYyI1aE_FtA"
    onChange={handleCaptchaChange}
  />
</div>

            <DivTag className="mt-5 flex flex-col items-center md:items-center">
                <Button
                    text={isLoading ? "Logging in..." : "Login"}
                    className={`text-center text-white bg-blue-900 shadow-xl  shadow-white  bg-gradient-to-r from-blue-400 to-blue-900 font-semibold rounded-md px-3 py-1 text-xl hover:from-blue-900 hover:to-blue-400 border-1 ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    id="login"
                    name="login"
                    onClick={handleLogin} 
                    disabled={isLoading}
                  >
                    </Button>
          
              {/* <Link to="/ForgotPasswordPage" className="mt-10 mb-11 font-bold text-dark underline">Forgot Password</Link>
              <Link to="/LockScreen" className="font-bold text-dark underline">Lock Screen</Link> */}
            </DivTag>
          </DivTag>
        </FormTag>
      </DivTag>
     
         <ToastContainer />
    </>
  );
};

export default LoginUserPage;









