import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LoginUserPage from './Pages/LoginPages/LoginUserPage';
import ForgotPasswordPage from './Pages/LoginPages/ForgotPasswordPage';
import Auth2StepsPage from './Pages/LoginPages/Auth2StepsPage';
import ConfirmAuth2Steps from './Pages/LoginPages/ConfirmAuth2Steps';
import WhitelistIp from './Pages/ApiSetting/WhitelistIp';
import WhitelistYourBankAc from './Pages/MyAccount/WhitelistYourBankAc';
import BusinessWalletStructure from './Pages/MyAccount/BusinessWalletStructure';
import CommissionCharges from './Pages/ApiServices/CommissionCharges';
import LockScreen from './Pages/LoginPages/LockScreen';
import PayoutReport from './Pages/PayoutMenu/PayoutReport';
import ServicePage from './Pages/ApiServices/ServicePage';
import RechargeAndBBPSReport from './Pages/RechargeAndBBPS/RechargeAndBBPSReport';
import Dashboard from './Pages/DashboardMenu/Dashboard';
import ProfileApiUser from './Pages/MyAccount/ProfileApiUser';
import VanCollectionReport from './Pages/CollectMenu/VanCollectionReport';
import MainLayout from './Layouts/MainLayout';
import AuthWrapper from './Components/AuthWrapper';
import useInactivityLogout from './Services/InactiveLogout';
import './App.css';
import AddFund from './Pages/ApiServices/AddFund';
import ApiLink from './Pages/ApiServices/ApiLink';
import HelpDesk from './Pages/MyAccount/HelpDesk';
import { ProfileProvider } from './context/ProfileContext';
import Loader from './Loader';
import EquifaxReport from './Pages/EquifaxMenu/EquifaxReport/EquifaxReport';
import EquifaxCrScore from './Pages/EquifaxMenu/EquifaxCrScore/EquifaxCrScore';
import HighmarkCrScore from './Pages/EquifaxMenu/HighmarkCrScore/HighmarkCrScore';

function App() {
  useInactivityLogout(600000); // Set the inactivity timeout to 10 minutes

  return (
    <Router>
      <RoutesWrapper>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LoginUserPage />} />
          <Route path="/ForgotPasswordPage" element={<ForgotPasswordPage />} />
          <Route path="/Auth2StepsPage" element={<Auth2StepsPage />} />
          <Route path="/ConfirmAuth2Steps" element={<ConfirmAuth2Steps />} />

          {/* Protected Routes */}
          <Route
            element={
              <AuthWrapper>
                <MainLayout />
              </AuthWrapper>
            }
          >
            <Route path="/Dashboard" element={<ProfileProvider><Dashboard /></ProfileProvider>} />
            <Route path="/ProfileApiUser" element={<ProfileApiUser />} />
            <Route path="/VanCollectionReport" element={<VanCollectionReport />} />
            <Route path="/WhitelistIp" element={<WhitelistIp />} />
            <Route path="/WhitelistYourBankAc" element={<WhitelistYourBankAc />} />
            <Route path="/CommissionCharges" element={<CommissionCharges />} />
            <Route path="/AddFund" element={<AddFund />} />
            <Route path="/ApiLink" element={<ApiLink />} />
            <Route path="/HelpDesk" element={<HelpDesk />} />
            <Route path="/EquifaxReport" element={<EquifaxReport /> } />
            <Route path="/EquifaxCrScore" element={<EquifaxCrScore />} />
            <Route path="/HighmarkCrScore" element={<HighmarkCrScore />} />
            <Route path="/BusinessWalletStructure" element={<BusinessWalletStructure />} />
            <Route path="/PayoutReport" element={<PayoutReport />} />
            <Route path="/ServicePage" element={<ServicePage />} />
            <Route path="/RechargeAndBBPSReport" element={<RechargeAndBBPSReport />} />
            <Route path="/LockScreen" element={<LockScreen />} />
          </Route>
        </Routes>
      </RoutesWrapper>
    </Router>
  );
}

function RoutesWrapper({ children }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 2 seconds

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      {children}
    </>
  );
}

export default App;
