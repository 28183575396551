import React, { useState } from 'react';

const ReportModal = ({ isOpen, onClose,highmarkResponseData,submittedName,submittedMobile }) => {
  const [isPrinting, setIsPrinting] = useState(false);

  if (!isOpen) return null; // Do not render anything if not open

  const printDiv = () => {
    setIsPrinting(true);
    
    // Create a temporary window for printing
    const printWindow = window.open('', '', 'height=600,width=800');
    
    const printContents = document.getElementById("invoicedata").innerHTML;

    // Write the content to the new window
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">');
    printWindow.document.write('</head><body >');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    
    printWindow.document.close(); // Close the document for rendering
    printWindow.focus(); // Focus on the new window
    
    setTimeout(() => {
        printWindow.print(); // Trigger print
       // printWindow.close(); // Close the print window after printing
        
        // Close the modal after 3 seconds
       // onClose();
        setIsPrinting(false);
    }, 100); // Slight delay before printing to ensure the content is rendered

};

const getPaymentStatusColor = (status) => {
  if (!status) return "bg-gray-500"; // Default for missing status
  
  switch (status.trim().toUpperCase()) {
    case "STD":  // Standard payment
      return "bg-green-500"; // Green for success
    case "XXX":  // Missed payment or other invalid data
      return "bg-red-500";   // Red for failure
    case "NEW":  // New account or recent
      return "bg-yellow-500"; // Yellow for new
    case "SUB":  // Substandard payment status
      return "bg-orange-500"; // Orange for substandard
    case "SMA":  // Special mention account
      return "bg-blue-500"; // Blue for special
    case "LSS":  // Loss status
      return "bg-purple-500"; // Purple for loss
    default:
      return "bg-gray-500";  // Default for other cases
  }
};

    return (
        <div className="fixed inset-0 flex  flex-col items-center justify-center z-50 backdrop-blur-sm bg-white bg-opacity-50 md:p-0 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full md:max-w-3xl overflow-y-auto max-h-[100vh]" id="invoicedata">
                <div className="a4-page">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                        <div className="col-span-2">
                            <img 
                                src="https://imgs.search.brave.com/6iV5QNxEpERp5OAG16hROi1fGXw0TI7pe8Po1HzXnjI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9kMXB0/NncybXQyeHFzby5j/bG91ZGZyb250Lm5l/dC9BY3VDdXN0b20v/U2l0ZW5hbWUvREFN/LzAxOS9FcXVpZmF4/LUxvZ28uanBn"
                                className="w-20"
                                alt="Company Logo"
                            />
                        </div>
                        <div className="col-span-8 md:text-center text-center">
                            <h4 className="text-2xl font-bold">Credit Report</h4>
                        </div>
                        <div className="col-span-2 md:text-end text-end">
                        Consumer Credit Score: <strong className="text-xl">
                        {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"]}
                        </strong>
                        </div>
                    </div>

                    <div className="flex justify-between items-start flex-wrap">
  {/* Start section */}
  <div className="">
    <p><i>Client ID:</i> <span className="client_id">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["BATCH-ID"]}</span></p>
    <p><i>Report Order No:</i> <span className="report_order_no">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["REPORT-ID"]}</span></p>
  </div>

  {/* End section */}
  <div className="text-right">
    <p><i>Date:</i> <span className="report_date">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["DATE-OF-REQUEST"]}</span></p>
    {/* <p><i>Time:</i> <span className="report_time">{highmarkResponseData?.InquiryResponseHeader?.Time}</span></p> */}
  </div>
</div>

<div className='flex justify-center'>
        <p className='font-bold text-white'>Personal Details</p>
    </div>
<div className="border border-blue-500 p-2 rounded-md">
   
  <div className="grid grid-cols-2 md:grid-cols-2  gap-1">
    {/* Consumer Name */}
    <div>
      <p><i>Consumer Name</i><span> : {submittedName}</span></p>
    </div>
    <div>
      <p><i>D.o.b</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["DOB"]}</span></p>
    </div>
    <div>
      <p><i>Age</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["AGE"]}</span></p>
    </div>
    <div>
      <p><i>Gender</i><span> : {highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.PersonalInfo?.Gender}</span></p>
    </div>
    <div>
      <p><i>Mob</i><span> : {submittedMobile}</span></p>
    </div>
  </div>
</div>

                    <hr className="my-2" />
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Address</div>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-2 py-1">Sn</th>
      <th className="border border-black px-2 py-1">Address</th>
    </tr>
  </thead>
  <tbody className="address-info">
    {highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.AddressInfo?.map((address, index) => (
      <tr key={address.Seq}>
        <td className="border border-black px-2 py-1 font-bold">Address : {address.Seq}</td>
        <td className="border border-black px-2 py-1">{address.Address}</td>
      </tr>
    ))}
  </tbody>
</table>

                       {/* Credit Score Section */}
                    <div className=" bg-blue-900 md:text-white p-2 text-white mt-2">Equifax Score</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr className='text-left'>
      <th className="border border-black px-2 py-1">Score Name</th>
      <th className="border border-black px-2 py-1">Score</th>
      <th className="border border-black px-2 py-1">Scoring Elements</th>
    </tr>
  </thead>
  <tbody className="scoreDetailsInfo">
  {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.map((scoreDetail, index) => (
    <tr key={index} className=''>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-TYPE"]}</td>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-VALUE"]}</td>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-FACTORS"] || scoreDetail?.["SCORE-COMMENTS"]}</td>
    </tr>
  ))}
</tbody>

</table>
                         {/* Accounts Section */}
                    <div className=" bg-blue-900 md:text-white text-white p-2 mt-4">Account Details</div>
                    <table className="min-w-full table-auto border border-black text-left">
      <thead className="bg-gray-200">
        <tr className="border">
          <th className="border border-black px-2 py-1">Account Type</th>
          <th className="border border-black px-2 py-1">Sanction Amount</th>
          <th className="border border-black px-2 py-1">Balance</th>
          <th className="border border-black px-2 py-1">OverDue</th>
          <th className="border border-black px-2 py-1">Account Status</th>
          <th className="border border-black px-2 py-1">Date Opened</th>
        </tr>
      </thead>
      <tbody>
  {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.map((response, index) => (
    <React.Fragment key={index}>
      {response?.["LOAN-DETAILS"] && (
        <>
          <tr className="border">
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["DISBURSED-AMT"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["CURRENT-BAL"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["ACCOUNT-STATUS"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</td>
          </tr>
          {response?.["LOAN-DETAILS"]?.["COMBINED-PAYMENT-HISTORY"] && (
            <tr>
              <td colSpan={6} className="border border-black px-1 py-0">
                <div className="flex flex-wrap">
                  {response["LOAN-DETAILS"]["COMBINED-PAYMENT-HISTORY"]
                    .split('|') // Split the string into an array of records
                    .map((history, idx) => {
                      const statusCode = history.split(',')[1]?.split('/')[1]; // Extract the payment status code
                      return (
                        <div key={idx} className="mx-1">
                          <span
                            className={`flex items-center justify-center w-6 h-6 text-white rounded-full ${getPaymentStatusColor(statusCode)}`} // Pass status code to function
                          >
                            {idx + 1} {/* Displaying the index + 1 for ascending order */}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </td>
            </tr>
          )}
        </>
      )}
    </React.Fragment>
  ))}
</tbody>




    </table>

                      {/* Recent Activity Section */}
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Recent Activity (Last 90 Days)</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-4 py-2">Total Inquiries</th>
      <th className="border border-black px-4 py-2">Accounts Opened</th>
      <th className="border border-black px-4 py-2">Accounts Updated</th>
      <th className="border border-black px-4 py-2">Accounts Delinquent</th>
    </tr>
  </thead>
  <tbody className="recentActivity">
    <tr className="border border-black text-center">
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.TotalInquiries}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsOpened}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsUpdated}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsDeliquent}</td>
    </tr>
  </tbody>
</table>
                     </div>
                          {/* Summary Section */}
                     <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Summary</div>
                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className='border border-black px-2 py-1'>Number of Accounts</th>
                <th className='border border-black px-2 py-1'>Total Balance Amount</th>
                <th className='border border-black px-2 py-1'>Recent Account</th>
              </tr>
            </thead>
            <tbody class="summary1">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</td>
                <td className='border border-black px-2 py-1'>
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}
                </td>
            </tr>
            </tbody>
                    </table>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
                 <tr>
                <th className="border border-black px-2 py-1">Number of Open Accounts</th>
                <th className="border border-black px-2 py-1">Total Past Due Amount</th>
                <th className="border border-black px-2 py-1">Oldest Account</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[5]?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</td>
            </tr>
            </tbody>
                     </table>

                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className="border border-black px-2 py-1">Total High Credit</th>
                <th className="border border-black px-2 py-1">Total Sanction Amount</th>
                <th className="border border-black px-2 py-1">Single Highest Sanction</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalHighCredit}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SANCTIONED-AMOUNT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.SingleHighestSanctionAmount}</td>
            </tr>
            </tbody>
                         </table>
                 </div>
                 <div className="flex justify-center mt-4">
                <button
                    className="bg-green-500 text-white p-2 rounded hover:bg-green-700"
                    onClick={printDiv}
                    disabled={isPrinting}
                >
                    Print
                </button>
                <button
                    className="bg-red-500 text-white p-2 rounded hover:bg-red-700"
                    onClick={onClose} // This should close the modal
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ReportModal;
