import React, {useState, useEffect} from 'react'
import { DivTag } from '../../../Components/Tags/Tags'
import Header from '../../../Components/Header'
import Seo from '../../../Components/SeoComponent/Seo'
import { HeadTag, InputField } from '../../../Components/Tags/Tags'
import { showSuccessToast, showErrorToast,showInfoToast,showWarningToast } from '../../../utils/toastUtils'
import GaugeChart from 'react-gauge-chart'
import { AddHighMarkData } from '../../../Services/EquifaxCScore/highmark'
import {fetchCityService, fetchEquifaxEQCodeService, fetchProfile, fetchStateService } from '../../../Services/EquifaxCScore/equifax'
import ReportModal from './ReportModal'
import html2pdf from 'html2pdf.js';
import { CreditData2 } from './data2'

const HighmarkCrScore = () => {

const [cusName, setCusName] = useState();
const [dob, setDob] = useState('');
const [mobile, setMobile] = useState('');
const [aadharNo, setAadharNo] = useState('');
const [panNo, setPanNo] = useState('');
const [voterID, setVoterId] = useState('');
const [rationCard, setRationCard] = useState('');
const [drivingLicence, setDrivingLicence] = useState('');
const [passport, setPassport] = useState('');
const [address, setAddress] = useState('');
const [state, setState] = useState('');
const [city, setCity] = useState('');
const [pinCode, setPinCode] = useState('');
const [nominee, setNominee] = useState('');
const [selectGender, setSelectGender] = useState('');
const [selectRelation, setSelectRelation] = useState('');
const [enquiryPurpose, setEnquiryPurpose] = useState('');
const [enquiryStage, setEnquiryStage] = useState('');
const [errors, setErrors] = useState({});
const [isLoading, setIsLoading] = useState(false);
const [creditScore, setCreditScore] = useState('');  
const [isModalOpen, setModalOpen] = useState(false);
const [stateList, setStateList] = useState([]);
const [cityList, setCityList] = useState([]);
const [enquiryList, setEnquiryList] = useState([]);
const [profileData, setProfileData] = useState([]); 
const [highmarkResponseData, setHighmarkResponseData] = useState('');
const [submittedName, setSubmittedName] = useState('');
const [submittedMobile, setSubmittedMobile] = useState('');
const [submittedAddress, setSubmittedAddress] = useState('');

const maxScore = 900;

// Validate Form Input
const validateForm = () => {
    const newErrors = {};
    if (!cusName) newErrors.cusName = 'Cus Name is required.';
    if (!dob) newErrors.dob = 'DOB is required.';
    if (!mobile) newErrors.mobile = 'Mobile is required.' ;
    if (!address) newErrors.address = 'Address is required.';
    if (!city) newErrors.city = 'City is required';
    if (!state) newErrors.state = 'State is required.';
    if (!pinCode) newErrors.pinCode = 'Pin Code is required.';
    if (!nominee) newErrors.nominee = 'Nominee is required.';
    if (!selectRelation) newErrors.selectRelation = 'Relation is required.';
    if (!enquiryPurpose) newErrors.enquiryPurpose = 'Enquiry Purpose is required';
    if (!selectGender) newErrors.selectGender = 'Gender is required';

    // Check if at least one of the ID fields is filled, otherwise show a common error
    if (
        !aadharNo && 
        !panNo && 
        !voterID && 
        !rationCard && 
        !drivingLicence && 
        !passport
    ) {
        newErrors.identification = 'At least one ID is required (Aadhar No., Pan No., Voter ID, Ration Card, Driving Licence, Passport).';
    }

    setErrors(newErrors);
    // Return true if no errors, false if there are errors
    return Object.keys(newErrors).length === 0;
};

 
const handleDateChange = (event) => {
  const dateValue = event.target.value;
  setDob(dateValue);
};
   
   
const handlesubmit = async () => {
  try {
      // Validate the form first
      const isValid = validateForm();
      if (!isValid) {
          showErrorToast('Please correct the form errors.');
          return; // Exit if validation fails
      }
      // Call the AddEquifax function to submit data
      setIsLoading(true);
      await AddHighmark();
      
      setTimeout(() => {
        // Hide the loader after 1 second
        setIsLoading(false);
        // Show success message upon successful submission
        // showSuccessToast('Data submitted successfully');
    }, 1000);
  } catch (error) {
    console.error('Error submitting data:', error);
    showErrorToast('Validate the Required Field');
    setIsLoading(false); // Hide loader in case of error
  }
};

const handleDownloadPDF = () => {
  // Use setTimeout to ensure the modal or content is fully rendered before capturing the content
  setTimeout(() => {
    const element = document.getElementById("CreditReport");

    if (element) {
      // Temporarily remove the 'hidden' class from the element for capturing the PDF
      element.classList.remove('hidden');

      // Clone the element to avoid affecting the original content
      const clonedElement = element.cloneNode(true);

      // Adjust styles of cloned element to ensure it captures everything in the PDF
      clonedElement.style.maxHeight = 'none'; // Make sure full content is visible
      clonedElement.style.overflow = 'visible'; // Remove any overflow issues

      // Create a new temporary div to render the cloned content for PDF generation
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute'; // Ensure it's not in view but still in DOM
      tempDiv.style.visibility = 'hidden';
      tempDiv.appendChild(clonedElement);
      document.body.appendChild(tempDiv);

      // Generate the PDF from the cloned content
      html2pdf()
        .from(clonedElement)
        .set({
          margin: [0.2, 0.2, 0.2, 0.2], // Adjust margins to suit your needs
          filename: 'Credit_Report.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, backgroundColor: 'white' }, // Set high resolution
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        })
        .save()
        .then(() => {
          // Close modal and remove temporary div after PDF is saved
          document.body.removeChild(tempDiv);
          // Add 'hidden' class back to the original element after PDF is generated
          element.classList.add('hidden');
        })
        .catch(() => {
          // Handle any errors that might occur during PDF generation
          showErrorToast("Failed to generate PDF");
          // Add 'hidden' class back to the original element in case of error
          element.classList.add('hidden');
        });
    } else {
      showErrorToast("No data found to download");
    }
  }, 10); // Slightly increased timeout to ensure rendering is complete
};


const getPaymentStatusColor = (status) => {
  if (!status) return "bg-gray-500"; // Default for missing status
  
  switch (status.trim().toUpperCase()) {
    case "STD":  // Standard payment
      return "bg-green-500"; // Green for success
    case "XXX":  // Missed payment or other invalid data
      return "bg-red-500";   // Red for failure
    case "NEW":  // New account or recent
      return "bg-yellow-500"; // Yellow for new
    case "SUB":  // Substandard payment status
      return "bg-orange-500"; // Orange for substandard
    case "SMA":  // Special mention account
      return "bg-blue-500"; // Blue for special
    case "LSS":  // Loss status
      return "bg-purple-500"; // Purple for loss
    default:
      return "bg-gray-500";  // Default for other cases
  }
};

  
// Add HighMark Data
async function AddHighmark() {
  try {
    const dobdate = new Date(dob).toLocaleDateString('en-CA'); // Format DOB to 'YYYY-MM-DD'
    setIsLoading(true);
    // Log customer details for debugging
   // console.log("Customer Details: ", { cusName, mobile, address });

    // Ensure all required fields are present and set default values for optional fields
    const formData = {
      api_id: profileData.user_id || "",  // Using profile data
      api_key: profileData.api_key || "",
      token_id: profileData.token_id || "",
      applicant_name: cusName ? [cusName] : [],  // Applicant name array
      dob: dobdate || "",  // Formatted DOB

      // Phones array (Ensure number is provided)
      phones: Array.isArray(mobile) && mobile[0]?.number
        ? [{ type: "MOB", number: mobile[0].number }]
        : [],

      // IDs array (PAN, Aadhar, etc.)
      ids: panNo
        ? [{ type: "ID07", value: panNo }]  // Assuming PAN is selected as type "ID07"
        : [],

      // Address object (Ensure all fields are populated)
      address: {
        type: "RES",  // Assuming "RES" (residential) as the type
        address_1: address || "",  // Address line 1 (e.g., street)
        city: city || "",          // City
        state: state || "",        // State
        pin: pinCode || ""         // Postal code
      },

      // Loan Details (Ensure required fields are provided)
      loan_details: enquiryPurpose && enquiryStage
        ? {
            inquiry_unique_ref_no: "1234567890",  // Provide a valid reference number
            cred_rpt_id: null,                    // Set as null
            cred_req_typ: "INDV",                 // Individual credit request type
            cred_rpt_trn_id: "1234567890TRN",     // Provide a valid transaction ID
            cred_inq_purps_typ: enquiryPurpose,   // Inquiry purpose type
            credit_inquiry_stage: enquiryStage,   // Credit inquiry stage
            loan_amount: 500000,                  // Provide a valid loan amount or default
            mbr_id: "MEMBER12345",                // Provide a valid member ID
            branch_id: "BRANCH12345",             // Provide a valid branch ID
            los_app_id: "LOS12345"                // Provide a valid loan application ID
          }
        : {
            inquiry_unique_ref_no: "MissingRefNo",  // Default values
            cred_rpt_trn_id: "MissingTrnID",
            loan_amount: 500000  // Default loan amount
          },

      // Other details if available
      aadhar_no: aadharNo || "",
      pan_no: panNo || "",
      voter_id: voterID || "",
      ration_no: rationCard || "",
      driving_licence: drivingLicence || "",
      passport: passport || "",
      nominee: nominee || "",
      select_gender: selectGender || "",
    };

   // console.log("Prepared formData:", formData);

    // Call the service and pass the formData
    const response = await AddHighMarkData(formData);
   // console.log("Response:", response);

    // Check the response for success
    if (!response?.data?.success) {
      console.error("API Error Response:", response);
      showErrorToast(response?.data?.message || "An error occurred.");
      setIsLoading(false);
      return;
    }


    // Process the Highmark response data
    setHighmarkResponseData(response.data);

    // Extract credit score
    const creditScore = response?.data?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"];
    if (creditScore) {
      setCreditScore(creditScore);
    } else {
      showErrorToast("crddd");
    }

    setSubmittedName(cusName);
    setSubmittedMobile(mobile[0]?.number);
    setSubmittedAddress(`${address}, ${city}, ${state}, ${pinCode}`);


    // Clear the form or reset necessary state variables (optional)
    setCusName('');
    setDob('');
    setMobile('');
    setAadharNo('');
    setPanNo('');
    setVoterId('');
    setRationCard('');
    setDrivingLicence('');
    setPassport('');
    setAddress('');
    setState('');
    setCity('');
    setPinCode('');
    setNominee('');
    setSelectRelation('');
    setEnquiryPurpose('');

    setIsLoading(false);  // Hide loader
  } catch (error) {
    console.error("Error in Highmark:", error);
    setIsLoading(false);
    showErrorToast("An unexpected error occurred.");
  }
}


   // ENd

  useEffect(() => {
    async function fetchProfileData() {
      try {
        const response = await fetchProfile(); // Your API call for profile
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data', error); } }
    fetchProfileData();
  }, []);


// Fetch State List
const fetchstate = async () => {
  try {
    const response =await fetchStateService();
    setStateList(response.data);
      // console.log("data in page", response)
  } catch (error) {
    console.error("Error in fetchin ", error);
  }
}
useEffect(() => {
  fetchstate();
}, []);
// ENd


  // Fetch City List
const fetchcity = async () => {
  try {
    const response = await fetchCityService({stateId:state});
    setCityList(response.data);
      // console.log("data in page", response)
  } catch (error) {
    console.error("Error in fetchin ", error);
  }
}
useEffect(() => {
  fetchcity();
}, [state]);
// ENd


// Fetch Enquiry List
const fetchenquiry = async () => {
  try {
    const response = await fetchEquifaxEQCodeService();
    setEnquiryList(response.data);
  } catch (error) {
    console.error("Error in Fetching", error);
  }
}
useEffect(() => {
  fetchenquiry();
},[])
// ENd

  return (
    <>
    <Seo />
   
      <Header heading='HighMark Cr Score' />
      <DivTag className="container grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-1 md:gap-2 p-2 bg-white  md:mt-1 mt-0 shadow-blue-600 rounded-md">

      <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Customer Name<strong className='text-red-700'>*</strong></HeadTag>
            <input 
        type='text'
        id="cus_name"
        name="cus_name"
        value={cusName}
        className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
        placeholder="Enter Customer Name"
        onChange={(e) => setCusName(e.target.value)}
        />
         {errors.cusName && <p className="text-red-600 text-sm font-mono">{errors.cusName}</p>}
        </DivTag>

        <DivTag className="flex flex-col">
    <HeadTag className="text-md font-serif">  
        D.O.B <strong className='text-red-700'>*</strong>
    </HeadTag>
    <input
      type="date"
      name="dob"
      id="dob"
      value={dob}
      onChange={handleDateChange}
      className="w-full border border-blue-600 rounded-md py-1"
    />
    
    {errors.dob && <p className="text-red-600 text-sm font-mono">{errors.dob}</p>}
</DivTag>

        <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Mobile <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Mobile"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="mobile"
              id="mobile"
              onChange={(e) => setMobile(e.target.value)}
            />
             {errors.mobile && <p className="text-red-600 text-sm font-mono">{errors.mobile}</p>}
          </DivTag>
      
          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Aadhar No.<strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Aadhar No."
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="aadhar_no"
              id="aadhar_no"
              onChange={(e) => setAadharNo(e.target.value)}
            />
             {/* {errors.aadharNo && <p className="text-red-600 text-sm font-mono">{errors.aadharNo}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Pan No. <strong className='text-red-700'>*</strong></HeadTag>           
             <InputField
              type="text"
              placeholder="Enter Pan"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="pan_no"
              id="pan_no"
              onChange={(e) => setPanNo(e.target.value)}
            />
             {/* {errors.panNo && <p className="text-red-600 text-sm font-mono">{errors.panNo}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Voter ID <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Voter ID"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="voter_id"
              id="voter_id"
              onChange={(e) => setVoterId(e.target.value)}
            />
             {/* {errors.voterID && <p className="text-red-600 text-sm font-mono">{errors.voterID}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Ration Card No<strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Ration Card No"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="ration_no"
              id="ration_no"
              onChange={(e) => setRationCard(e.target.value)}
            />
             {/* {errors.rationCard && <p className="text-red-600 text-sm font-mono">{errors.rationCard}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Driving Licence <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Driving Licence"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="driving_licence"
              id="driving_licence"
              onChange={(e) => setDrivingLicence(e.target.value)}
            />
             {/* {errors.drivingLicence && <p className="text-red-600 text-sm font-mono">{errors.drivingLicence}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Passport <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Passport"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="passport"
              id="passport"
              onChange={(e) => setPassport(e.target.value)}
            />
             {/* {errors.passport && <p className="text-red-600 text-sm font-mono">{errors.passport}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Address <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter address"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="address"
              id="address"
              onChange={(e) => setAddress(e.target.value)}  
            />
         {errors.address && <p className="text-red-600 text-sm font-mono">{errors.address}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">select state<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="enquiry_purpose"
            id="enquiry_purpose"
            value={state}
            onChange={(e) => setState(e.target.value)}
             >
            <option value="" disabled selected>select state</option>
         {
        stateList && stateList.length > 0 ? stateList.map((item,index)=>(
            <option value={item.abbreviation} key={index}>{item.state_name}</option>
          ))
          :""
         }
         </select>
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">select city<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_city"
            id="select_city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
             >
            <option value="" disabled selected>select city</option>
         {
        cityList && cityList.length > 0 ? cityList.map((item,index)=>(
            <option value={item.city_name} key={index}>{item.city_name}</option>
          ))
          :""
         }
         </select>
          </DivTag>


          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Pin-Code <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter pin code"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="pin code"
              id="pin code"
              onChange={(e) => setPinCode(e.target.value)}
            />
              {errors.pinCode && <p className="text-red-600 text-sm font-mono">{errors.pinCode}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Nominee <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter nominee"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="nominee"
              id="nominee"
              onChange={(e) => setNominee(e.target.value)}
            />
             {errors.nominee && <p className="text-red-600 text-sm font-mono">{errors.nominee}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Select Gender<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded text-center shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_Gender"
            id="select_Gender"
            onChange={(e) => setSelectGender(e.target.value)}
             >
            <option value="" disabled selected>Select Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Other</option>
            </select>
            {errors.selectGender && <p className="text-red-600 text-sm font-mono">{errors.selectGender}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Select Relation<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 text-center rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_relation"
            id="select_relation"
            onChange={(e) => setSelectRelation(e.target.value)}
             >
            <option value="" disabled selected>Select Relation</option>
            <option value="F">Father</option>
            <option value="H">Husband</option>
            <option value="B">Brother</option>
            <option value="S">Son</option> 
            <option value="U">Son In Law</option>
            <option value="Y">Father In Law</option>
            <option value="O">Other</option> 
            <option value="M">Mother</option>                      
            <option value="W">Wife</option>
            <option value="C">Sister</option>
            <option value="D">Daughter</option>
            <option value="V">Daughter-In-Law</option>
            <option value="Z">Mother-In-Law</option>
            <option value="X">Sister-In Law</option>
            </select>
            {errors.selectRelation && <p className="text-red-600 text-sm font-mono">{errors.selectRelation}</p>}
          </DivTag>


          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">Enquiry Purpose<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 text-center rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_relation"
            id="select_relation"
            onChange={(e) => setEnquiryPurpose(e.target.value)}
             >
            <option value="" disabled selected>Enquiry Purpose</option>
            <option value="ACCT-ORIG">ACCT-ORIG</option>
            <option value="ACCT-MAINT">ACCT-MAINT</option>
            <option value="OTHER">OTHER</option>
            </select>
            {errors.enquiryPurpose && <p className="text-red-600 text-sm font-mono">{errors.enquiryPurpose}</p>}
          </DivTag>


          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">Enquiry Stage<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 text-center rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="enquiry_stage"
            id="enquiry_stage"
            onChange={(e) => setEnquiryStage(e.target.value)}
             >
            <option value="" disabled selected>Enquiry Stage</option>
            <option value="PRE-DISB">ACCT-ORIG</option>
            <option value="PRE-SCREEN">ACCT-MAINT</option>
            <option value="UW-REVIEW">UW-REVIEW</option>
            <option value="COLLECTION">COLLECTION</option>
            <option value="RENEWAL">RENEWAL</option>
            </select>
            {errors.enquiryStage && <p className="text-red-600 text-sm font-mono">{errors.enquiryStage}</p>}
          </DivTag>

         <div className="flex justify-center">
            <button className='rounded-md p-1 md:w-16 w-16 md:mt-6 md:ml-6 ml-32  bg-blue-700 text-white mt-2 mb-1'
            onClick={handlesubmit}
            >Submit</button>
        </div>


        {/* <p>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"]}</p> */}
        
        </DivTag>
        <div className='w-full text-center'>
        {errors.identification && <p className='text-red-600 text-sm font-mono'>{errors.identification}</p>}
        </div>
      
        { 
     highmarkResponseData === "" ?  
    "" : 
        <div class="flex flex-col p-4 bg-white shadow-inner md:mt-1 shadow-blue-600 rounded-md">
        <span className='text-center font-bold md:text-xl'>Hey Cus!</span>
  <h2 class="text-xl md:text-xl font-semibold text-center">Your Equifax Credit Score</h2>

    <div class="flex flex-col md:flex-row justify-center md:justify-center gap-4 md:gap-8 md:mt-2 mt-3 relative">
    <div class="w-full md:w-[400px] relative md:mt-10">
      
    <span class="text-red-500 md:font-bold absolute md:top-[20px] md:left-10 md:mt-16 -rotate-70
    top-[60px] left-5 
    ">Poor</span>
    <span class="text-yellow-400 md:font-bold absolute md:left-[104px] md:mt-1 -rotate-26
    left-[69px] top-[-7px] 
    ">Average</span>
    <span class="text-orange-600 md:font-bold absolute md:left-60 md:mt-0 md:rotate-20
    left-[12rem] mt-[-9px] rotate-20
    ">Good</span>
    <span class="text-green-800 md:font-bold absolute md:top-[20px] md:ml-[19.5rem] md:mt-16 rotate-70
    ml-[16rem] mt-[3.5rem]
    ">Excellent</span>

      <GaugeChart 
        id="gauge-chart1" 
        nrOfLevels={3} 
        percent={creditScore / maxScore}  // Normalized value for gauge
        needleColor={"#00008b"}
        textColor={"transparent"}  // Hides the percentage text
        needleBaseColor={"red"}
        arcsLength={[0.3, 0.3, 0.3, 0.3]} 
        colors={['#cc0000','#e6e600','#cc6600','#009900']}
      />
      <p class="text-black font-bold text-xl md:text-2xl absolute bottom-12 md:bottom-[5.5rem] 
      left-1/2 transform -translate-x-1/2"> 
        {creditScore}
      </p>
    </div>
   
    <div class="flex flex-col items-center md:items-start">
      <ul class="text-left text-sm md:text-base space-y-1 mt-4">
        <li><span class="font-bold">No of Accounts:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</li>
        <li><span class="font-bold">Active Accounts:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</li>
        <li><span class="font-bold">Total Balance Amount:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</li>
        <li><span class="font-bold">Total Pass Due:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[5]?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</li>
        <li><span class="font-bold">Recent Account:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
        {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</li>
        <li className=''><span class="font-bold">Oldest Account:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
        {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}
        </li>
      </ul>
      <ReportModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}  highmarkResponseData={highmarkResponseData} submittedName={submittedName} submittedMobile={submittedMobile}/>
      <div class="flex space-x-2 mt-4">
        <button 
          onClick={() => setModalOpen(true)} 
          class="bg-blue-600 text-white text-sm md:text-base rounded-md px-2 py-1">
          View
        </button>
        <button 
          class="bg-red-600 text-white text-sm md:text-base rounded-md px-2 py-1"
          onClick={handleDownloadPDF}
          >
          Download
        </button>
      </div>
    </div>
    
  </div>

        </div>
    }  

<>
<div id="CreditReport" className="hidden bg-white rounded-lg shadow-lg w-full md:max-w-3xl overflow-y-auto max-h-[100vh]">
                <div className="a4-page">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                        <div className="col-span-2">
                            <img 
                                src="https://imgs.search.brave.com/6iV5QNxEpERp5OAG16hROi1fGXw0TI7pe8Po1HzXnjI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9kMXB0/NncybXQyeHFzby5j/bG91ZGZyb250Lm5l/dC9BY3VDdXN0b20v/U2l0ZW5hbWUvREFN/LzAxOS9FcXVpZmF4/LUxvZ28uanBn"
                                className="w-20"
                                alt="Company Logo"
                            />
                        </div>
                        <div className="col-span-8 md:text-center text-center">
                            <h4 className="text-2xl font-bold">Credit Report</h4>
                        </div>
                        <div className="col-span-2 md:text-end text-end">
                        Consumer Credit Score: <strong className="text-xl">
                        {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"]}
                        </strong>
                        </div>
                    </div>

                    <div className="flex justify-between items-start flex-wrap">
  {/* Start section */}
  <div className="">
    <p><i>Client ID:</i> <span className="client_id">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["BATCH-ID"]}</span></p>
    <p><i>Report Order No:</i> <span className="report_order_no">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["REPORT-ID"]}</span></p>
  </div>

  {/* End section */}
  <div className="text-right">
    <p><i>Date:</i> <span className="report_date">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["DATE-OF-REQUEST"]}</span></p>
    {/* <p><i>Time:</i> <span className="report_time">{highmarkResponseData?.InquiryResponseHeader?.Time}</span></p> */}
  </div>
</div>

<div className='flex justify-center'>
        <p className='font-bold text-white'>Personal Details</p>
    </div>
<div className="border border-blue-500 p-2 rounded-md">
   
  <div className="grid grid-cols-2 md:grid-cols-2  gap-1">
    {/* Consumer Name */}
    <div>
      <p><i>Consumer Name</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["NAME"]}</span></p>
    </div>
    <div>
      <p><i>D.o.b</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["DOB"]}</span></p>
    </div>
    <div>
      <p><i>Age</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["AGE"]}</span></p>
    </div>
    <div>
      <p><i>Gender</i><span> : {highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.PersonalInfo?.Gender}</span></p>
    </div>
    <div>
      <p><i>Mob</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["PERSONAL-INFO-VARIATION"]?.[1]}</span></p>
    </div>
  </div>
</div>

                    <hr className="my-2" />
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Address</div>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-2 py-1">Sn</th>
      <th className="border border-black px-2 py-1">Address</th>
    </tr>
  </thead>
  <tbody className="address-info">
    {highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.AddressInfo?.map((address, index) => (
      <tr key={address.Seq}>
        <td className="border border-black px-2 py-1 font-bold">Address : {address.Seq}</td>
        <td className="border border-black px-2 py-1">{address.Address}</td>
      </tr>
    ))}
  </tbody>
</table>

                       {/* Credit Score Section */}
                    <div className=" bg-blue-900 md:text-white p-2 text-white mt-2">Equifax Score</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr className='text-left'>
      <th className="border border-black px-2 py-1">Score Name</th>
      <th className="border border-black px-2 py-1">Score</th>
      <th className="border border-black px-2 py-1">Scoring Elements</th>
    </tr>
  </thead>
  <tbody className="scoreDetailsInfo">
  {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.map((scoreDetail, index) => (
    <tr key={index} className=''>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-TYPE"]}</td>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-VALUE"]}</td>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-FACTORS"] || scoreDetail?.["SCORE-COMMENTS"]}</td>
    </tr>
  ))}
</tbody>

</table>
                         {/* Accounts Section */}
                    <div className=" bg-blue-900 md:text-white text-white p-2 mt-4">Account Details</div>
                    <table className="min-w-full table-auto border border-black text-left">
      <thead className="bg-gray-200">
        <tr className="border">
          <th className="border border-black px-2 py-1">Account Type</th>
          <th className="border border-black px-2 py-1">Sanction Amount</th>
          <th className="border border-black px-2 py-1">Balance</th>
          <th className="border border-black px-2 py-1">OverDue</th>
          <th className="border border-black px-2 py-1">Account Status</th>
          <th className="border border-black px-2 py-1">Date Opened</th>
        </tr>
      </thead>
      <tbody>
  {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.map((response, index) => (
    <React.Fragment key={index}>
      {response?.["LOAN-DETAILS"] && (
        <>
          <tr className="border">
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["DISBURSED-AMT"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["CURRENT-BAL"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["ACCOUNT-STATUS"]}</td>
            <td className="border border-black px-2 py-1">{response?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</td>
          </tr>
          {response?.["LOAN-DETAILS"]?.["COMBINED-PAYMENT-HISTORY"] && (
            <tr>
              <td colSpan={6} className="border border-black px-1 py-0">
                <div className="flex flex-wrap">
                  {response["LOAN-DETAILS"]["COMBINED-PAYMENT-HISTORY"]
                    .split('|') // Split the string into an array of records
                    .map((history, idx) => {
                      const statusCode = history.split(',')[1]?.split('/')[1]; // Extract the payment status code
                      return (
                        <div key={idx} className="mx-1">
                          <span
                            className={`flex items-center justify-center w-6 h-6 text-white rounded-full ${getPaymentStatusColor(statusCode)}`} // Pass status code to function
                          >
                            {idx + 1} {/* Displaying the index + 1 for ascending order */}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </td>
            </tr>
          )}
        </>
      )}
    </React.Fragment>
  ))}
</tbody>




    </table>

                      {/* Recent Activity Section */}
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Recent Activity (Last 90 Days)</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-4 py-2">Total Inquiries</th>
      <th className="border border-black px-4 py-2">Accounts Opened</th>
      <th className="border border-black px-4 py-2">Accounts Updated</th>
      <th className="border border-black px-4 py-2">Accounts Delinquent</th>
    </tr>
  </thead>
  <tbody className="recentActivity">
    <tr className="border border-black text-center">
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.TotalInquiries}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsOpened}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsUpdated}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsDeliquent}</td>
    </tr>
  </tbody>
</table>
                     </div>
                          {/* Summary Section */}
                     <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Summary</div>
                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className='border border-black px-2 py-1'>Number of Accounts</th>
                <th className='border border-black px-2 py-1'>Total Balance Amount</th>
                <th className='border border-black px-2 py-1'>Recent Account</th>
              </tr>
            </thead>
            <tbody class="summary1">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</td>
                <td className='border border-black px-2 py-1'>
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}
                </td>
            </tr>
            </tbody>
                    </table>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
                 <tr>
                <th className="border border-black px-2 py-1">Number of Open Accounts</th>
                <th className="border border-black px-2 py-1">Total Past Due Amount</th>
                <th className="border border-black px-2 py-1">Oldest Account</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[5]?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</td>
            </tr>
            </tbody>
                     </table>

                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className="border border-black px-2 py-1">Total High Credit</th>
                <th className="border border-black px-2 py-1">Total Sanction Amount</th>
                <th className="border border-black px-2 py-1">Single Highest Sanction</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalHighCredit}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SANCTIONED-AMOUNT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.SingleHighestSanctionAmount}</td>
            </tr>
            </tbody>
                         </table>
                 </div>
</>

  </>
  )
}

export default HighmarkCrScore















