import React from 'react';
import { Button, DivTag } from '../Tags/Tags';
import { Link } from 'react-router-dom';
import { FaCogs, FaDatabase } from 'react-icons/fa';

const PlanCard = ({ onButtonClick, serviceName, isAssigned, serviceId }) => {
  const handleButtonClick = () => {
    if (!isAssigned) {
      onButtonClick();
    } else {
      console.log('Service is already assigned, modal will not open.');
    }
  };

  return (
    <DivTag className="w-full sm:w-4/5 bg-slate-300 md:w-4/5 lg:w-4/5 xl:w-4/5 2xl:w-4/5 p-4 gap-4 rounded-lg shadow-lg hover:bg-blue-900 hover:text-white">
      <DivTag className="flex items-center justify-between">
        <DivTag className="pricing-icon mb-4 mt-2">
          {/* {icon} */}
          {isAssigned ? <FaCogs /> : <FaDatabase />}
        </DivTag>
      </DivTag>
      <p className="text-lg font-bold">{serviceName}</p>
      <p className="text-sm">{isAssigned ? "(Assigned)" : "(Not Assigned)"}</p>
      <Button
        className={`bg-blue-900 text-white px-2 py-1 mt-8 cursor-pointer rounded-lg hover:bg-purple-600 ${isAssigned ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleButtonClick}
        disabled={isAssigned} // Disable the button if the service is assigned
      >
        {isAssigned ? "Assigned" : "Start Trial"}
      </Button>
      <Button
        className="bg-blue-900 text-white px-2 py-1 mt-4 cursor-pointer rounded-lg hover:bg-purple-600 ml-2"
      >
        <Link to={`/ApiLink?serviceId=${serviceId}`}>ApiLink</Link>
      </Button>
    </DivTag>
  );
};

export default PlanCard;
