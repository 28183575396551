import React, { useState, useEffect } from 'react';
import './Header.css';
import QuickLinkTop from './QuickLinkTop';
import Notification from '../Layouts/Header/Notification';
import ModalWithPinInput from './ModalWithPinComponent/ModalWithPinLock';
import WalletBalanceCard from './WalletBalanceCard';
import $ from "jquery";
import { BiSearch, BiRupee } from 'react-icons/bi';
import { FaLock } from 'react-icons/fa';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { fetchProfile,profileImgPreview } from '../Services/profile/profileservice';
import { logout } from "../Services/authService";
import { removeToken } from '../utils/tokenUtils';
import { Fade as Hamburger } from 'hamburger-react';
import { FaArrowsAlt, FaArrowsAltH } from 'react-icons/fa';
import VoiceControl from '../Layouts/Header/VoiceControl';


const Header = ({ heading, value }) => {
    const [profile, setProfile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [profileImg, setProfileImg] = useState('');
    const [filename,setFilename] = useState('');

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const profileData = await fetchProfile();
                setProfile(profileData.data);
                setFilename(profileData.profile);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfileData();
    }, []);

    const fullScreen = () => {
        document.documentElement.requestFullscreen();
        $('.full_screen').addClass('hidden');
        $('.exit_full_screen').removeClass('hidden');
    };

    const exitFullScreen = () => {
        document.exitFullscreen();
        $('.full_screen').removeClass('hidden');
        $('.exit_full_screen').addClass('hidden');
    };

    const handleCloseTop = () => {
        const sidebarMain = document.querySelector('.siderbar_main');
        const mainComponent = document.querySelector('#main_component');
        const width = window.innerWidth;
        if (width <= 1024) {
            sidebarMain.classList.toggle('sidemar_main_3');
        } else {
            sidebarMain.classList.toggle('sidemar_main_2');
            mainComponent.classList.toggle('main_component2');
        }
    };

    const handleLogout = async () => {
        const response = await logout();
        console.log(response);
        if (response.status === "success") {
            removeToken();
            window.location.href = '/';
        } else {
            removeToken();
            window.location.href = '/';
        }
    };

    const toggleModal = () => {
        setIsModalOpen(prev => !prev);
    };

    const handlePinSubmit = (pin) => {
        // Implement your PIN validation logic here
        console.log('PIN submitted:', pin);
        toggleModal(); // Close the modal after submission
    };


    // useEffect(() => {
    //     var addScript = document.createElement('script');
    //     addScript.setAttribute(
    //         'src',
    //         '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    //     );
    //     document.body.appendChild(addScript);
    //     window.googleTranslateElementInit = googleTranslateElementInit;
    // }, []);

    // const googleTranslateElementInit = () => {
    //     window.googleTranslateElementInit = () => {
    //         new window.google.translate.TranslateElement(
    //             {
    //                 pageLanguage: 'en',
    //                 includedLanguages: 'en,es,hi,gu,bn,sv,ta,te,ur',
    //                 autoDisplay: true
    //             },
    //             'google_translate_element'
    //         );
    //     };
    // };

 {/* <div className='flex gap-2'>
                        <p>Language:</p>
                        <div id="google_translate_element" class="skiptranslate goog-te-gadget" > </div>
                    </div> */}


                    const [isDropdownOpen, setDropdownOpen] = useState(false);

                    const handleProfileClick = () => {
                        setDropdownOpen(!isDropdownOpen);
                    };
                

                
                     // Preview Profile Image
                    useEffect(() => {
                        const PreviewImg = async () => {
                        //   if (!filename) {
                        //     console.warn('Filename is undefined or empty');
                        //     return;
                        //   }
                          try {
                            const response = await profileImgPreview({ filename });
                            setProfileImg(response.dataUri); 
                          } catch (error) {
                            console.error('Error fetching image:', error);
                          }
                        };
                        PreviewImg();  
                      }, [filename]);
                      // ENd

                    //   const lat = localStorage.getItem("latitude");
                    //   const long = localStorage.getItem("longitude");


    return (
        <>
            {/* Modal component with props */}
            <ModalWithPinInput
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                correctPin="123456"       // Add Compare PIN From Server
                onPinSubmit={handlePinSubmit} 
            />

            <div className="flex justify-between mb-2">
                <div className="flex flex-col justify-center">
                    <div className='flex items-center'>
                        <div className="outer_search none_1220">
                            <div className="search-outer_search">
                                <input placeholder='Search here...' className="input_" type="text" />
                                <BiSearch className="search__icon" />
                            </div>
                        </div>
                        <Link to="/Addpayout">
                            <QuickLinkTop icon={<BiRupee className="none_1220" />} text='Add Payout' />
                        </Link>

                    <WalletBalanceCard
                    balance={profile ? profile.balance : "Loading..."} />
                    </div>
                    <marquee className='text-red-800 before_top_left_content w-56 sm:w-56 md:w-96 lg:w-96' behavior="" direction="left">
                        Do not Share UserId & Password to unauthorized person.
                    </marquee>
                </div>
                <div className="flex gap-2 items-center">
    {/* Button to open the modal (Lock icon) */}

    {/* <p className='text-sm'>Latitude: <b>{lat}</b> Longitude: <b>{long}</b></p> */}
    
    <button onClick={toggleModal} className="hidden md:block">
        <FaLock size={26} />
    </button>

    {/* Fullscreen icons */}
    <p onClick={fullScreen} className="full_screen cursor-pointer hidden md:block mx-1">
        <MdFullscreen size={20} />
    </p>
    <p onClick={exitFullScreen} className="exit_full_screen cursor-pointer hidden md:block mx-1">
        <MdFullscreenExit size={20} />
    </p>
    {/* Notification */}
    <div className="hidden md:block">
        <Notification />
    </div>
    {/* Logout icon */}
    <RiLogoutBoxLine size={26} onClick={handleLogout} />
    {/* <div className="flex items-center">
        <div className="mx-1">
            <p className='font-semibold'>{profile ? profile.name : "Loading..."}</p>
        </div>
            <img className='profile_pic cursor-pointer' src="assets/images/menprofile.webp" alt="Profile" />
    </div> */}

<div className="relative">
                    <img
                        className='profile_pic cursor-pointer'
                        src={profileImg}
                        alt="Profile"
                        onClick={handleProfileClick}
                    />
                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-2 bg-white rounded-md shadow-inner shadow-blue-900 w-48 z-50 text-center border border-blue-800">


                            <div className="flex items-center px-4 py-2 font-bold text-gray-700 border-b cursor-pointer bg-slate-200 rounded-md hover:bg-gray-200 hover:rounded-full">
                            <img
                        className='rounded-full h-6 w-6 mr-2'
                        src={profileImg}
                        alt="Profile"
                        onClick={handleProfileClick}
                    />
                                 {profile ? profile.name : "Loading..."}</div>
                            <div 
                className="flex items-center px-4 py-2 text-gray-700 cursor-pointer border-b font-semibold shadow-md transform hover:bg-gray-200 hover:rounded-full"
                onClick={toggleModal}
            >
                <FaLock size={20} className="mr-2 animate-bounce" />
                <p>Lock Screen</p>
            </div>

            {/* Fullscreen */}
            <div 
                className="flex items-center px-4 py-2 text-gray-700 cursor-pointer border-b font-semibold shadow-md hover:bg-gray-200 hover:rounded-full"
                onClick={fullScreen}
            >
                <FaArrowsAlt size={20} className="mr-2" />
                <p>Fullscreen</p>
            </div>

            {/* Exit Fullscreen */}
            <div 
                className="flex items-center px-4 py-2 text-gray-700 cursor-pointer border-b font-semibold shadow-md hover:bg-gray-200 hover:rounded-full"
                onClick={exitFullScreen}
            >
                <FaArrowsAltH size={20} className="mr-2" />
                <p>Exit Fullscreen</p>
            </div>
       
                           
                            <div className="flex items-center px-1 py-1 text-gray-700 cursor-pointer text-center hover:bg-gray-200 hover:rounded-full font-semibold shadow-lg">
                            <Notification  className="mr-2" />
                               <p> Notification</p>
                            </div>
                            
                            <div className="flex items-center px-5 py-1 text-gray-700 cursor-pointer text-center hover:bg-gray-200 hover:rounded-full font-semibold shadow-lg" onClick={handleLogout}>
                                   <RiLogoutBoxLine size={26}  className="mr-2" />
                                    <p className=''>Logout</p>
                                     </div>   
                                     <VoiceControl />                  
                             </div>
                             
                    )}
                </div>


</div>
            </div>
            <hr />
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <p onClick={handleCloseTop} className='menu_icon cursor-pointer mx-1'>
                        <Hamburger duration={0.8} />
                    </p>
                    <h1 id='typewriter' className="company_name text-lg font-bold text-gray-500">{heading}{value}</h1>
                </div>
            </div>
            <hr />
        </>
    );
};

export default Header;
