
import { postRequest } from "../config/AxiosConfig";
import { showSuccessToast, showErrorToast } from "../utils/toastUtils";
import API_URLS from "../config/BackendConfig";
import { storeToken, getToken } from '../utils/tokenUtils';
import Cookies from 'js-cookie';

export const logout = async () => {
  const token = getToken();
  const response = await postRequest({
    url: `${API_URLS.CUSTOMER}logout`,
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  });
  return response
};



const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(new Error("Location access denied or unavailable."));
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};



//  login and verifyPin functions, ensure only .set is used.
export const login = async ({ user_id, password }) => {
  try {
    const { latitude, longitude } = await getLocation(); // Get geolocation
    
    const response = await postRequest({
      url: API_URLS.CUSTOMER + "login",
      data: { user_id, password, latitude, longitude }, // Pass lat/long to API
    });

    const data = response;
    // console.log("datatt",latitude,longitude);
    if (data.accessToken) {
      const token = data.accessToken;
      storeToken(token); // Store token in cookies or local storage
      return {
        success: true,
        data: data,
        message: "Logged In Successfully!",
      };
    }

    return {
      success: false,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      message: error.message === "Location access denied or unavailable."
        ? "Please enable location services."
        : "Invalid login attempt.",
    };
  }
};



const handleAuthError = (error) => {
  if (error.response) {
    const errorMessage = error.response.data.message || "Login failed!";
    showErrorToast(`Error ${error.response.status}: ${errorMessage}`);
  } else if (error.request) {
    showErrorToast("No response received from server");
  } else {
    showErrorToast(`Error: ${error.message}`);
  }
};

export const verifyPin = async (pin, tempToken) => {
  try {
    const { latitude, longitude } = await getLocation(); // Get geolocation

    const response = await postRequest({
      url: `${API_URLS.CUSTOMER}verify-pin`,
      data: { pin, temp_token: tempToken, latitude, longitude }, // Pass lat/long to API
      headers: {
        Authorization: `Bearer ${tempToken}`, // Include tempToken in the header
      },
    });
    // console.log("tatatt",latitude,longitude);
    if (response.token) {
      const token = response.token;
      Cookies.set('authToken', token, { expires: 0.125 }); // Store token in cookies for 3 hours
      showSuccessToast("PIN Verified Successfully");
     
      return {
        success: true,
        message: "PIN Verified Successfully",
        token, // Return the token if needed
      };
    }

    showErrorToast(response.message || "Invalid PIN");
    return {
      success: false,
      message: response.message || "Invalid PIN",
    };
  } catch (error) {
    return {
      success: false,
      message: error.message === "Location access denied or unavailable."
        ? "Please enable location services."
        : "Server error occurred while verifying PIN.",
    };
  }
};




